import React from 'react'
import { StaticImage } from "gatsby-plugin-image";

import './Bio.css'

const Bio = () => (
  <div className="row margin-top-2 margin-bottom-2">
      <div className="col bio-image-col">
        <StaticImage
          src="../images/rob-blob.png"
          alt="Rob Allgood"
          placeholder="blurred"
          className="responsive-image"
        />
      </div>
      <div className="col bio-content-col">
          <h3 className="margin-top-0">About</h3>
          <p>Hi, I'm Rob, and I am a freelance web designer in Nashville, TN who likes to make time for reading, biking, baking and excluding the Oxford comma. I also love being creative and helping people fulfill their design needs. So email me at <b>dra3000@gmail.com</b> and let's talk about how we can work together.</p>
      </div>
  </div>
)

export default Bio
